import { Capacitor } from '@capacitor/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { DeviceId, DeviceInfo } from '@capacitor/device'
import { Device } from '@capacitor/device'

export const useGlobalStateStore = defineStore('globalState', () => {
  // 首页是否已经挂载，防止重复挂在或者路由栈丢失
  const homePageMounted = ref(false)
  function setHomePageMounted(value: boolean, duplicateLoadCb: () => void) {
    if (value && homePageMounted.value) {
      duplicateLoadCb()
    }
    homePageMounted.value = value
  }

  // 深链接的变量，延迟跳转
  const deepLinkRoute = ref<string | undefined>()
  function setDeepLinkRoute(value: string | undefined) {
    deepLinkRoute.value = value
  }

  // 禁用Swiper的滑动，防止手势冲突
  const disableSwipe = ref(false)
  function setDisableSwipe(value: boolean) {
    disableSwipe.value = value
  }

  const showCreateSekaiGuide = ref(true)
  function setShowCreateSekaiGuide(value: boolean) {
    showCreateSekaiGuide.value = value
  }

  const hasFetchInitSekai = ref(false)
  function setHasFetchInitSekai(value: boolean) {
    hasFetchInitSekai.value = value
  }

  const deviceInfo = ref<DeviceInfo>()
  const deviceId = ref<DeviceId>()
  async function initDeviceInfo() {
    deviceId.value = await Device.getId()
    deviceInfo.value = await Device.getInfo()
  }

  const availableDeviceInfo = computed(() => {
    return {
      deviceId: deviceId.value,
      deviceInfo: deviceInfo.value,
    }
  })

  const canAutoplayMedia = ref(Capacitor.isNativePlatform())
  function setCanAutoplayMedia(value: boolean) {
    canAutoplayMedia.value = value
  }

  /**
   * 修改名字弹窗
   */
  const openNameChange = ref(false)
  function setOpenNameChange(value: boolean) {
    openNameChange.value = value
  }

  return {
    disableSwipe,
    setDisableSwipe,

    homePageMounted,
    setHomePageMounted,

    showCreateSekaiGuide,
    setShowCreateSekaiGuide,

    hasFetchInitSekai,
    setHasFetchInitSekai,

    deepLinkRoute,
    setDeepLinkRoute,

    deviceId,
    deviceInfo,
    availableDeviceInfo,
    initDeviceInfo,

    canAutoplayMedia,
    setCanAutoplayMedia,

    openNameChange,
    setOpenNameChange,
  }
})
