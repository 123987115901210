import { defineStore } from 'pinia'
import { PushNotifications } from '@capacitor/push-notifications'
import { ref } from 'vue'

export const useNotificationStore = defineStore('notificationStore', () => {
  const notificationToken = ref<string | null>(null)

  const addListeners = async () => {
    await PushNotifications.addListener('registration', (token) => {
      console.info('Registration token: ', token.value)
      notificationToken.value = token.value
    })

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error)
    })

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Push notification received: ', notification)
    })

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue)
    })
  }

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!')
    }

    await PushNotifications.register()
    addListeners()
  }

  const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications()
    console.log('delivered notifications', notificationList)
  }
  return {
    addListeners,
    registerNotifications,
    getDeliveredNotifications,

    notificationToken,
  }
})
