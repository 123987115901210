import { Preferences } from '@capacitor/preferences'
import type { Ref } from 'vue'
import { onBeforeMount, ref, watch } from 'vue'

export function usePreference<T>(preferenceName: string, initialValue: T) {
  const preference = ref<T>(initialValue) as Ref<T>
  const preferenceIsLoaded = ref(false)

  onBeforeMount(async () => {
    preference.value = await getPreference() ?? initialValue
    preferenceIsLoaded.value = true
  })

  watch(preference, async (newVal) => {
    try {
      await Preferences.set({
        key: preferenceName,
        value: JSON.stringify(newVal),
      })
    }
    catch (error) {
      console.log(`[setPreference] error ${JSON.stringify(error, null, 2)}`)
    }
  })

  async function getPreference(): Promise<T | undefined> {
    try {
      const { value } = await Preferences.get({ key: preferenceName })
      if (value === null) {
        return undefined
      }
      return JSON.parse(value) as T
    }
    catch (error) {
      console.log(`[getPreference] error ${JSON.stringify(error, null, 2)}`)
    }
  }

  const removePreference = async () => {
    try {
      await Preferences.remove({ key: preferenceName })
    }
    catch (error) {
      console.log(`[removePreference] error ${JSON.stringify(error, null, 2)}`)
    }
  }

  return {
    preference,
    preferenceIsLoaded,
    removePreference,
  }
}
