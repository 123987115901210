export function getCurrentTime() {
  const _time = new Date()
  const y = String(_time.getFullYear())
  const m = String(_time.getMonth() + 1 < 10 ? `0${_time.getMonth() + 1}` : _time.getMonth() + 1)
  const d = String(_time.getDate() < 10 ? `0${_time.getDate()}` : _time.getDate())
  const hh = String(_time.getHours() < 10 ? `0${_time.getHours()}` : _time.getHours())
  const mm = String(_time.getMinutes() < 10 ? `0${_time.getMinutes()}` : _time.getMinutes())
  const ss = String(_time.getSeconds() < 10 ? `0${_time.getSeconds()}` : _time.getSeconds())
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
}
