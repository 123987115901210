import { FirebaseAnalytics } from '@capacitor-firebase/analytics'

class FirebaseAnalyticsUtil {
  static setUserId = async (userId) => {
    await FirebaseAnalytics.setUserId({
      userId,
    })
  }

  static setUserProperty = async (property) => {
    await FirebaseAnalytics.setUserProperty(property)
  }

  static setCurrentScreen = async (screenName) => {
    await FirebaseAnalytics.setCurrentScreen({
      screenName,
    })
  }

  static logEvent = async (name: string, params?: Map<string, string>) => {
    await FirebaseAnalytics.logEvent({
      name,
      params,
    })
  }

  static setEnabled = async () => {
    await FirebaseAnalytics.setEnabled({
      enabled: true,
    })
  }

  static isEnabled = async () => {
    const { enabled } = await FirebaseAnalytics.isEnabled()
    return enabled
  }

  static resetAnalyticsData = async () => {
    await FirebaseAnalytics.resetAnalyticsData()
  }
}

export default FirebaseAnalyticsUtil
