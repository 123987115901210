import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { IonicVue } from '@ionic/vue'
import { Network } from '@capacitor/network'
import { Capacitor } from '@capacitor/core'
import * as Sentry from '@sentry/capacitor'
import * as SentryVue from '@sentry/vue'
import App from './App.vue'
import router from './router'
import { i18n } from '@/locales/i18n'
import './app.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

import 'virtual:uno.css'

import 'vant/lib/index.css'

function getConfig() {
  return {
    swipeBackEnabled: false,
  }
}
const pinia = createPinia()
const app = createApp(App)
  .use(IonicVue, getConfig())
  .use(i18n)
  .use(pinia)
  .use(router)

router.isReady().then(() => {
  // 等待网络权限被授予
  waitForNetworkPermission().then(() => {
  // 当网络权限被授予后，加载应用程序
    app.mount('#app')
  })
})

async function waitForNetworkPermission() {
  // 如果是 iOS 平台，检查网络权限
  if (Capacitor.getPlatform() === 'ios') {
    const status = await Network.getStatus()
    if (status.connectionType === 'none') {
      // 如果权限为 none，等待网络权限变化
      await new Promise<void>((resolve) => {
        Network.addListener('networkStatusChange', (status) => {
          if (status.connected) {
            resolve()
          }
        })
      })
    }
  }
}

Sentry.init({
  app,
  dsn: 'https://929993e57db35863485ab357d7930b12@o4507109026824192.ingest.us.sentry.io/4507109031149568',
  integrations: [
    new SentryVue.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
    }),
    new SentryVue.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
},
// Forward the init method from @sentry/vue
SentryVue.init,
)
