import { ref } from 'vue'

export type FetchState = 'beforeReq' | 'waitRes' | 'success' | 'failed'

export function useFetchState() {
  const fetchState = ref<FetchState>('beforeReq')

  return {
    fetchState,
  }
}
