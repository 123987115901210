import type { LocaleConfig } from './index'

export const defaultConfig: LocaleConfig = {
  customerSupportEmail: 'support@sekai.chat',
  links: {
    privacyPolicy:
      'https://v3zw3ctzcsg.larksuite.com/docx/XqymdzKlbovG1UxuW0Qu2evksrJ',
    termsOfService:
      'https://v3zw3ctzcsg.larksuite.com/docx/PkdddMYltoWAKUxBu1SuHF04sag',
  },
}
