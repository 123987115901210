<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
    <g clip-path="url(#clip0_51828_10477)">
      <path d="M17.4273 7.4998C17.299 7.5988 15.0346 8.8682 15.0346 11.6908C15.0346 14.9556 17.916 16.1106 18.0022 16.1392C17.9889 16.2096 17.5445 17.721 16.483 19.261C15.5366 20.6162 14.5482 21.9692 13.0445 21.9692C11.5408 21.9692 11.1538 21.1002 9.41794 21.1002C7.72629 21.1002 7.12481 21.9978 5.74938 21.9978C4.37396 21.9978 3.41425 20.7438 2.31081 19.2038C1.03268 17.3954 0 14.586 0 11.9196C0 7.6428 2.79509 5.3746 5.54595 5.3746C7.00762 5.3746 8.22604 6.3294 9.14373 6.3294C10.0172 6.3294 11.3794 5.3174 13.0423 5.3174C13.6725 5.3174 15.9369 5.3746 17.4273 7.4998ZM12.2528 3.5068C12.9405 2.695 13.427 1.5686 13.427 0.4422C13.427 0.286 13.4138 0.1276 13.385 0C12.2661 0.0418 10.9349 0.7414 10.1322 1.6676C9.50196 2.3804 8.91376 3.5068 8.91376 4.6486C8.91376 4.8202 8.9425 4.9918 8.95577 5.0468C9.02654 5.06 9.14152 5.0754 9.25651 5.0754C10.2604 5.0754 11.5231 4.4066 12.2528 3.5068Z" fill="#bfbfbf" />
    </g>
    <defs>
      <clipPath id="clip0_51828_10477">
        <rect width="18" height="22" fill="#bfbfbf" />
      </clipPath>
    </defs>
  </svg>
</template>
