import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { usePreference } from '@/composables/usePreference'

interface requestIds {
  time: string
  requestId: string
}
export const useDebugStore = defineStore('Debug', () => {
  const enableVoice = ref<boolean>(true)

  const setEnableVoice = (value: boolean) => {
    enableVoice.value = value
  }
  const {
    preference: lastTime,
  } = usePreference<number>('lastTime', 0)

  const {
    preference: count,
  } = usePreference<number>('count', 0)

  const {
    preference: maxCount,
  } = usePreference<number>('maxCount', 5)

  const {
    preference: interval,
  } = usePreference<number>('interval', 500)

  const {
    preference: requestIds,
  } = usePreference<requestIds[]>('requestIds', [])

  const {
    preference: storyTellerMode,
  } = usePreference<boolean>('storyTellerMode', false)

  const isDebug = computed<boolean>(() => count.value >= maxCount.value)

  // the letter interval for the first narration
  const firstNarrationStreamSpeed = ref<number>(10)
  // the letter interval stream
  const streamSpeed = ref<number>(10)

  return {
    lastTime,
    storyTellerMode,
    count,
    maxCount,
    interval,
    isDebug,
    requestIds,
    enableVoice,
    setEnableVoice,

    streamSpeed,
    firstNarrationStreamSpeed,
  }
})
