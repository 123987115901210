<script setup lang="ts">
import { IonLoading, IonModal, IonToast, isPlatform } from '@ionic/vue'
import { ref, watch } from 'vue'
import GoogleIcon from '@/components/svg/GoogleIcon.vue'
import AppleIcon from '@/components/svg/AppleIcon.vue'
import type { FetchState } from '@/composables/useFetchState'
import { localeConfig } from '@/locales/config'

interface Props {
  isOpen: boolean
  loginState: FetchState
}

interface Emits {
  (e: 'close'): void
  (e: 'click:continueWithGoogle'): void
  (e: 'click:continueWithApple'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const toastIsOpen = ref(false)

watch(() => props.loginState, (newState) => {
  if (newState === 'failed') {
    toastIsOpen.value = true
  }
})

function closeModal() {
  emit('close')
}
</script>

<template>
  <ion-modal
    :is-open="isOpen"
    :backdrop-dismiss="true"
    :initial-breakpoint="0.45"
    :breakpoints="[0.45, 0.6, 0.75]"
    class="custom-ion-modal"
    @did-dismiss="closeModal"
  >
    <div class="h-full pt-6 pb-4 px-4 flex flex-col items-center text-center bg-[#1f1f1f]">
      <p class="pt-4 text-[#bbbbbb] text-5.5 fw-700 leading-8">
        {{ $t('authModal.title') }}
      </p>
      <p class="pt-2 text-[#8e8e8e]">
        {{ $t('authModal.description') }}
      </p>
      <div class="w-full pt-6 flex flex-col gap-4">
        <button
          class="w-full h-12 px-4 rounded-full bg-[#292929] border-0.5px border-divider border-solid flex justify-between items-center gap-2 text-[#bbbbbb] text-4 fw-700 active:op-85"
          @click="() => { emit('click:continueWithGoogle') }"
        >
          <google-icon />
          <p>{{ $t('authModal.continueWithGoogle') }}</p>
          <div class="w-20px h-20px flex-shrink-0" />
        </button>
        <button
          v-if="isPlatform('ios') && !isPlatform('mobileweb')"
          class="w-full h-12 px-4 rounded-full bg-[#292929] border-0.5px border-divider border-solid flex justify-between items-center gap-2 text-[#bbbbbb] text-4 fw-700 active:op-85"
          @click="() => { emit('click:continueWithApple') }"
        >
          <apple-icon />
          <p>{{ $t('authModal.continueWithApple') }}</p>
          <div class="w-20px h-20px flex-shrink-0" />
        </button>
      </div>
      <p class="pt-5 text-[#6b6b6b] text-3 fw-400 leading-4">
        <span>{{ $t('authModal.noticePart1') }}</span>
        <span class="px-1">
          <a
            :href="localeConfig.links.termsOfService" target="_blank"
            class="text-[#bfbfbf] fw-500"
          >{{ $t('authModal.termsOfService') }}</a>
        </span>
        <span>{{ $t('authModal.noticePart2') }}</span>
        <span class="px-1">
          <a
            :href="localeConfig.links.privacyPolicy" target="_blank"
            class="text-[#bfbfbf] fw-500"
          >{{ $t('authModal.privacyPolicy') }}</a>
        </span>
      </p>
    </div>
    <ion-loading :is-open="loginState === 'waitRes'" :message="$t('homePage.loggingIn')" class="custom-loading" />
    <ion-toast
      :is-open="toastIsOpen"
      :message="$t('authModal.loginFailed')"
      :duration="3000"
      position="middle"
      class="custom-toast"
      @did-dismiss="() => toastIsOpen = false"
    />
  </ion-modal>
</template>

<style scoped>
ion-modal.custom-ion-modal {
  --border-radius: 16px;
}
ion-loading.custom-loading {
  --background: var(--ion-background-color);
  --spinner-color: var(--ion-text-secondary-color);
  color: var(--ion-text-secondary-color);
}
ion-toast.custom-toast{
  --background: var(--ion-background-color);
  --spinner-color: var(--ion-text-secondary-color);
  color: var(--ion-text-secondary-color);
}
</style>
