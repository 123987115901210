import { createRouter, createWebHistory } from '@ionic/vue-router'
import type { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/home' },
  {
    path: '/home',
    component: () => import('@/views/HomePage.vue'),
  },
  // {
  //   path: '/demo',
  //   component: () => import('@/views/DevDemoEntry.vue'),
  // },
  // {
  //   path: '/demo/:widget',
  //   component: () => import('@/views/DevDemo.vue'),
  // },
  // {
  //   path: '/settings',
  //   component: () => import('@/views/SettingsPage.vue'),
  // },
  // {
  //   path: '/create-universe',
  //   component: () => import('@/views/createUniverse/CreateSekaiScaffold.vue'),
  // },
  {
    path: '/sekai/:id',
    component: () => import('@/views/SingleSekaiPage.vue'),
    props: route => ({ id: Number(route.params.id) }),
  },
  // {
  //   path: '/character-setting/:id',
  //   component: () => import('@/views/createUniverse/CharacterSettingPage.vue'),
  // },
  // {
  //   path: '/character-voice-select/:id',
  //   component: () => import('@/views/createUniverse/CharacterVoiceSelectPage.vue'),
  // },
  // {
  //   path: '/universes-list/:category',
  //   component: () => import('@/views/UniversesListPage.vue'),
  // },
  // {
  //   path: '/delete-account',
  //   component: () => import('@/views/DeleteAccountPage.vue'),
  // },
  {
    path: '/:path(.*)*',
    component: () => import('@/views/NotFoundPage.vue'),
  },
  // {
  //   path: '/character/pose/:id',
  //   component: () => import('@/views/CharacterPoseSetting.vue'),
  // },
  // {
  //   path: '/image-crop/:url',
  //   component: () => import('@/views/ImageCropPage.vue'),
  // },
  // {
  //   path: '/video/:url',
  //   component: () => import('@/views/VideoPlayerPage.vue'),
  // },
  // {
  //   path: '/video-upload',
  //   component: () => import('@/views/VideoUploadPage.vue'),
  // },
  // {
  //   path: '/select-msg',
  //   component: () => import('@/views/MessageSelectionPage.vue'),
  // },
  // {
  //   path: '/follow',
  //   component: () => import('@/views/FollowUsersPage.vue'),
  // },
  // {
  //   path: '/profile',
  //   component: () => import('@/views/UserProfile.vue'),
  // },
  // {
  //   path: '/menu',
  //   component: () => import('@/components/LeftMenu.vue'),
  // },
  // {
  //   path: '/notification',
  //   component: () => import('@/views/NotificationCenter.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

export default router
