import { createI18n } from 'vue-i18n'
import en from '../lang/en.json'
import zh from '../lang/zh.json'

export const availableLanguageCodes = ['zh', 'en'] as const
export type AvailableLanguageCode = typeof availableLanguageCodes[number]

interface LanguageOption {
  languageCode: AvailableLanguageCode
  englishLabel: string
  localizedLabel: string
}

export const languageOptions: LanguageOption[] = availableLanguageCodes.map(languageCode => ({
  languageCode,
  englishLabel: getLanguageLabel(languageCode) ?? languageCode,
  localizedLabel: getLanguageLabel(languageCode, languageCode) ?? languageCode,
}))

const messages: Record<AvailableLanguageCode, any> = {
  zh,
  en,
}

export const i18n = createI18n({
  legacy: false,
  // globalInjection: true,
  locale: 'en',
  messages,
})

export function setLanguage(langCode: string): string | undefined {
  const availableLocales = i18n.global.availableLocales as string[]

  if (availableLocales.includes(langCode)) {
    i18n.global.locale.value = langCode as AvailableLanguageCode
    console.log(`Set language as ${langCode}`)
    return langCode
  }

  const shortCode = langCode.split('-')[0]
  if (availableLocales.includes(shortCode)) {
    i18n.global.locale.value = shortCode as AvailableLanguageCode
    console.log(`Set language as ${langCode}`)
    return langCode
  }

  console.error(`${langCode} is not available in i18n, valid options: ${i18n.global.availableLocales.join(', ')}`)
}

/**
 * Get language label ru -> Russian or ru -> русский
 * @param {string} languageCode Example zh-CN, ru
 * @param {string} outputLanguage Default en
 * @return Language label
 */
export function getLanguageLabel(languageCode: string, outputLanguage = 'en'): string | undefined {
  const lang = new Intl.DisplayNames([outputLanguage], { type: 'language' })
  return lang.of(languageCode)
}
