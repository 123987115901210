import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useGlobalStateStore } from './globalState'
import { useNotificationStore } from './notifications'
import type { LoginType, User } from '@/types'
import { usePreference } from '@/composables/usePreference'
import { signInWithApple, signInWithGoogle, signout } from '@/services/auth/firebase'
import { authService } from '@/services/auth'
import { useFetchState } from '@/composables/useFetchState'
import { userService } from '@/services/user'

export const useUserStore = defineStore('user', () => {
  const DEFAULT_USER: User = {
    userId: 0,
    userName: '',
    userDescription: '',
    firstTimeLogin: true,
    userAvatarUrl: 'https://aiu-avatar.s3.amazonaws.com/avatar/userAvatar.png',
    createUniverseCount: 0,
    favoriteUniverseCount: 0,
    createdUniverses: [],
    favoriteUniverses: [],
    followerCount: 0,
    followingCount: 0,
  }

  const { fetchState: loginState } = useFetchState()
  const { fetchState: deleteAccountState } = useFetchState()
  const globalStore = useGlobalStateStore()
  const notificationStore = useNotificationStore()

  const user = ref<User>(DEFAULT_USER)

  const {
    preference: accessToken,
    preferenceIsLoaded: accessTokenIsLoaded,
  } = usePreference<string>('accessToken', '')

  const {
    preference: accessExp,
    preferenceIsLoaded: accessExpIsLoaded,
  } = usePreference<number>('accessExp', 0)

  const {
    preference: refreshToken,
    preferenceIsLoaded: refreshTokenIsLoaded,
  } = usePreference<string>('refreshToken', '')

  const {
    preference: refreshExp,
    preferenceIsLoaded: refreshExpIsLoaded,
  } = usePreference<number>('refreshExp', 0)

  const tokensAreLoaded = computed<boolean>(() => accessTokenIsLoaded.value && accessExpIsLoaded && refreshTokenIsLoaded.value && refreshExpIsLoaded.value)

  const isLoggedIn = computed<boolean>(() => tokensAreLoaded.value && accessToken.value !== '' && refreshToken.value !== '')

  function resetUser() {
    user.value = DEFAULT_USER
  }

  function resetTokens() {
    accessToken.value = ''
    accessExp.value = 0
    refreshToken.value = ''
    refreshExp.value = 0
  }

  async function deleteAccount() {
    if (deleteAccountState.value === 'waitRes') {
      return
    }

    try {
      deleteAccountState.value = 'waitRes'
      await userService.accountPermanentDeactivation()
      resetUser()
      resetTokens()
      deleteAccountState.value = 'success'
    }
    catch (error) {
      deleteAccountState.value = 'failed'
      console.log(`${JSON.stringify(error, null, 2)}`)
    }
  }

  async function getUserInfo() {
    const { user: res } = (await userService.getMyInfo()).data
    user.value = {
      ...user.value,
      userId: res.userId,
      userName: res.userName,
      userAvatarUrl: res.avatarUrl,
      favoriteUniverseCount: res.favoriteUniverseCount,
      createUniverseCount: res.createUniverseCount,
      followerCount: res.followerCount,
      followingCount: res.followingCount,
      firstTimeLogin: res.firstTimeLogin,
    }
    if (res.firstTimeLogin) {
      globalStore.setOpenNameChange(true)
    }

    if (notificationStore.notificationToken) {
      await userService.uploadNotificationToken(notificationStore.notificationToken)
    }
  }

  async function logIn(type: LoginType) {
    let res: any = {}
    try {
      loginState.value = 'waitRes'
      switch (type) {
        case 'google':
          res = await signInWithGoogle()
          break
        case 'apple':
          res = await signInWithApple()
          break
      }
      const payload = {
        userName: res.user.displayName || res.user.email.split('@')[0],
        avatarUrl: res.user.photoURL || 'https://userpics.craftwork.design/images/s-5-img-14@2x.png',
        token: res._tokenResponse.idToken,
      }
      console.log('payload', payload)

      const result = await authService.getToken(payload)
      console.log('login result', result)

      user.value = {
        userId: 0,
        userName: payload.userName,
        userAvatarUrl: payload.avatarUrl,
        firstTimeLogin: true,
        userDescription: '',
        favoriteUniverses: [],
        createdUniverses: [],
        favoriteUniverseCount: 0,
        createUniverseCount: 0,
        followerCount: 0,
        followingCount: 0,
      }
      accessToken.value = result.access_token
      accessExp.value = result.access_exp
      refreshToken.value = result.refresh_token
      refreshExp.value = result.refresh_exp

      await getUserInfo()
      // TODO: REFRESH THE SEKAI WRAPPER BY SOME WATCHER
      loginState.value = 'success'
    }
    catch (error) {
      loginState.value = 'failed'
      console.error('login error', error)
    }
  }

  async function logOut() {
    await signout()
    // TODO: REFRESH THE SEKAI WRAPPER BY SOME WATCHER
    resetUser()
    resetTokens()

    if (!isLoggedIn.value) {
      console.log('signout success')
    }
  }

  async function RefreshToken() {
    try {
      const { access_token, access_exp } = await authService.refreshToken({ refreshToken: refreshToken.value })
      if (!(access_token && access_exp)) {
        logOut()
        throw new Error('Can\'t find data or access_token or access_exp')
      }
      accessToken.value = access_token
      accessExp.value = access_exp
    }
    catch (err) {
      console.error(err)
      logOut()
    }
  }

  return {
    user,
    isLoggedIn,
    tokensAreLoaded,
    accessToken,
    accessExp,
    refreshToken,
    refreshExp,
    loginState,
    logIn,
    logOut,
    getUserInfo,
    deleteAccount,
    deleteAccountState,
    RefreshToken,
  }
})
