import { get, post, put } from '../base'
import type { ResponseWrapper } from '@/types/common'
import type { NotificationResModel } from '@/types/notification'
import type { SimpleUniverseResponse } from '@/types/universe'
import type { FollowUserModel } from '@/types/user'

interface User {
  userId: number
  userName: string
  avatarUrl: string
  firstTimeLogin: boolean
  createUniverseCount: number
  favoriteUniverseCount: number
  favoriteTags: Tag[]
  favoriteGroups: FavoriteGroup[]
  favoriteChars: FavoriteChar[]
  followerCount: number
  followingCount: number
}

interface FavoriteChar {
  charId: number
  charName: string
  tags: Tag[]
  charDescription: string
  charAvatarUrl: string
  createTimeUtc: number
  creatorUserId: number
  followerCount: number
}

interface Tag {
  tagId: number
  tagDisplayName: string
}

interface FavoriteGroup {
  groupId: number
  groupName: string
  groupAvatar: string
}

interface GetMyInfoResult {
  user: User
  // -1: No follow between the two users
  // 0: login user follows this user
  // 1: this user follows login user
  // 2: mutual follow
  followStatus: number
}

export interface Char {
  charId: number
  charName: string
  tags: Tag[]
  charDescription: string
  charAvatarUrl: string
  createTimeUtc: number
  creatorUserId: number
  followerCount: number
}

interface FavoriteUniverseOperationsPayload {
  universeId: number
  operate: 'add' | 'remove'
}

interface FavoriteUniverseOperationsResult {
  message: string
}

interface ReportWatchedUniversePayload {
  universeId: number
}

interface ReportWatchedUniverseResult {
  message: string
}

interface AccountPermanentDeactivationResult {
  message: string
}

class UserService {
  async getMyInfo() {
    return get<ResponseWrapper<GetMyInfoResult>>('/user/v2/getMyInfo')
  }

  async getUserInfo(userId: string) {
    return get<ResponseWrapper<GetMyInfoResult>>(
      `/user/v2/getUserInfo?userId=${userId}`,
    )
  }

  async updateUserInfo(userName?: string, userFirstLogin?: boolean) {
    const data = {}
    if (userName) {
      data['userName'] = userName
    }
    if (userFirstLogin !== undefined) {
      data['firstTimeLogin'] = userFirstLogin
    }
    return put<any>('user/v2/updateUserInfo', data)
  }

  async uploadNotificationToken(token: string) {
    return put<any>('user/v2/updateUserInfo', {
      registrationToken: token,
    })
  }

  async getFavoriteUniverses(userId?: string) {
    const url = userId
      ? `/user/v2/getFavoriteUniverses?userId=${userId}`
      : '/user/v2/getFavoriteUniverses'
    return get<ResponseWrapper<SimpleUniverseResponse[]>>(url)
  }

  async getCreatedUniverses(userId?: string) {
    const url = userId
      ? `/user/v2/getCreatedUniverses?userId=${userId}`
      : '/user/v2/getCreatedUniverses'
    return get<ResponseWrapper<SimpleUniverseResponse[]>>(url)
  }

  async getChattedSekai(userId?: string) {
    const url = userId
      ? `/user/v2/getChattedUniverses?userId=${userId}`
      : '/user/v2/getChattedUniverses'
    return get<ResponseWrapper<SimpleUniverseResponse[]>>(url)
  }

  async favoriteUniverseOperations(payload: FavoriteUniverseOperationsPayload) {
    return post<FavoriteUniverseOperationsResult>(
      '/user/favoriteUniverseOperations',
      payload,
    )
  }

  async reportWatchedUniverse(payload: ReportWatchedUniversePayload) {
    return post<ReportWatchedUniverseResult>(
      '/user/reportWatchedUniverse',
      payload,
    )
  }

  async accountPermanentDeactivation() {
    return post<AccountPermanentDeactivationResult>(
      '/user/accountPermanentDeactivation',
      {},
    )
  }

  async getFollower(userId: string) {
    return get<ResponseWrapper<FollowUserModel[]>>(
      `/user/v2/getAllFollower?userId=${userId}`,
    )
  }

  async getFollowing(userId: string) {
    return get<ResponseWrapper<FollowUserModel[]>>(
      `/user/v2/getAllFollowing?userId=${userId}`,
    )
  }

  async followOperations(userId: number, operate: 'follow' | 'unfollow') {
    return post<ResponseWrapper<any>>('/user/v2/followOperation', {
      userId,
      operate,
    })
  }

  async fetchNotifications() {
    return get<ResponseWrapper<NotificationResModel[]>>('/sysmsg/getAllSysmsg')
  }
}

export const userService = new UserService()
