import { Capacitor } from '@capacitor/core'
import { getApps, initializeApp } from 'firebase/app'

import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  signInWithCredential,
  signOut,
} from 'firebase/auth'

// Initialize Firebase - information is stored in .env file in the root directory
const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
}

console.log(import.meta.env.VITE_APP_API_URL, { firebaseConfig })

const APP = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0]
console.log('app', APP, getApps())
console.log('Capacitor.isNativePlatform', Capacitor.isNativePlatform())

if (Capacitor.isNativePlatform()) {
  // require to work appropriately on native devices
  initializeAuth(APP, {
    persistence: indexedDBLocalPersistence,
  })
}

export async function signInWithApple() {
  const result = await FirebaseAuthentication.signInWithApple({
    skipNativeAuth: true,
  })

  const provider = new OAuthProvider('apple.com')
  const credential = provider.credential({
    idToken: result.credential?.idToken,
    rawNonce: result.credential?.nonce,
  })
  const auth = getAuth()
  const res = await signInWithCredential(auth, credential)
  console.log('signInWithApple result', res)
  return res
}

export async function signInWithGoogle() {
  const result = await FirebaseAuthentication.signInWithGoogle()

  const credential = GoogleAuthProvider.credential(result.credential?.idToken)
  const auth = getAuth()
  const res = await signInWithCredential(auth, credential)
  console.log('signInWithCredential result', res)

  return res
}

export async function signout() {
  await FirebaseAuthentication.signOut()
  const auth = getAuth()
  await signOut(auth)
}
