import { defaultConfig } from './defaultConfig'

export interface LocaleConfig {
  customerSupportEmail: string
  links: {
    termsOfService: string
    privacyPolicy: string
  }
}

export const localeConfig = defaultConfig
