import { defineStore } from 'pinia'
import { ref } from 'vue'

export type ToastPosition = 'middle' | 'top' | 'bottom'

export const useToastStore = defineStore('toast', () => {
  const toast = ref<string>('')
  const toastPosition = ref<ToastPosition>('middle')
  const toastIsLoaded = ref<boolean>(false)

  const timer = ref<undefined | ReturnType<typeof setTimeout>>()

  function setToast(message: string, position: ToastPosition = 'middle') {
    if (timer.value) {
      clearTimeout(timer.value)
      resetToast()
    }

    toast.value = message
    toastPosition.value = position
    toastIsLoaded.value = true

    // reset the toast after 2 seconds
    timer.value = setTimeout(() => {
      resetToast()
    }, 2000)
  }

  function resetToast() {
    toast.value = ''
    toastPosition.value = 'middle'
    toastIsLoaded.value = false
  }

  return {
    toast,
    toastPosition,
    toastIsLoaded,
    setToast,
    resetToast,
  }
})
