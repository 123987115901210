import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useUserStore } from './user'
import type { LoginType } from '@/types'

export const useAuthStore = defineStore('auth', () => {
  const isModalOpened = ref<boolean>(false)
  const userStore = useUserStore()

  function openModal() {
    isModalOpened.value = true
  }

  function closeModal() {
    isModalOpened.value = false
  }

  async function handleLogin(type: LoginType) {
    await userStore.logIn(type).then(() => {
      closeModal()
    })
  }

  return {
    isModalOpened,
    openModal,
    closeModal,
    handleLogin,
  }
})
