import { post } from '../base'

interface GetTokenRequestPayload {
  userName: string // 用户的名称
  avatarUrl: string // 用户的头像
  token: string // 登陆token
}

interface GetTokenResult {
  access_token: string
  access_exp: number
  refresh_token: string
  refresh_exp: number
}
interface RefreshTokenRequestPayload {
  refreshToken: string
}

interface RefreshTokenResult {
  access_token: string
  access_exp: number
}

class AuthService {
  // 后端验证token
  async getToken(payload: GetTokenRequestPayload): Promise<GetTokenResult> {
    return post<GetTokenResult>('/auth/verifyFirebaseToken', payload)
  }

  // 刷新token
  async refreshToken(payload: RefreshTokenRequestPayload): Promise<RefreshTokenResult> {
    return post<RefreshTokenResult>('/auth/refreshToken', payload)
  }
}

export const authService = new AuthService()
